<template>
  <ion-page>
    <!-- <teleport to="#header-portal">
    </teleport> -->
    <toolbar :redirect="state.redirect"></toolbar>
    <ion-content fullscreen>
        <ion-grid>
          <ion-row class="ion-justify-content-around">
            <ion-col size-sm="12" size-md="10" size-xs="12">
            <ion-card v-for="post,idx in state.posts" :key="idx" class="ion-padding ion-no-margin">
              <teleport to="head">
                <meta property="og:title" :content="post.title">
                <meta property="og:url" :content="`https://sukisan.com/${post.country}/${post.category.code}/${post.id}/`">
                <meta property="og:site_name" content="Sukisan Pinoy SG Marketplace">
                <meta property="og:type" content="website">
                <meta property="og:image" :content="`${post.images[0].fileurl}`">
                <meta property="og:description" :content="`${post.unit_type} ${post.category.code} at ${post.location} for ${post.price}`">
                <meta property="description" :content="`${post.unit_type} ${post.category.code} at ${post.location} for ${post.price} brought to you by Sukisan Pinoy SG Marketplace`">
              </teleport>
              <ion-row class="card-title" lines="none">
                <!-- <ion-text slot="start" color="primary"> -->
                {{ post.title }}
                <!-- </ion-text> -->
              </ion-row>
              <ion-card-subtitle class="card-subtitle">{{ getRelativeDatetime(post) }}</ion-card-subtitle>
              <ion-slides pager="true" :options="slideOpts"  class="post-images">
                <ion-slide v-for="(image, index) in post.images" :key="index">
                  <ion-img :src="image.fileurl" :alt="`${post.title} #${index} Pinoy SG Room for Rent`"/>
                </ion-slide>
              </ion-slides>
              <ion-row class="ion-justify-content-between">
                <ion-col size="auto">
                  <div class="item_price">${{ post.price }}</div>
                </ion-col>
                <ion-col size="auto">
                  <div class="item_price">{{ post.unit_type }}</div>
                  <div 
                    class="fb-share-button" 
                    data-layout="button" 
                    data-size="large"
                    :data-href="`https://sukisan.com/${post.country}/${post.category.code}/${post.id}/`" 
                    ></div>
                </ion-col>
                <ion-col size="auto" v-if="showChat(post.uid)">
                  <!-- <ion-buttons class="ion-no-padding"> -->
                    <ion-button class="green-bg"
                      @click.prevent="initiateChat"
                      >
                      <ion-icon :icon="chatboxOutline"></ion-icon>&nbsp;Message
                    </ion-button>
                  <!-- </ion-buttons> -->
                </ion-col>
              </ion-row>
              <ion-row v-if="post.nearest_mrt">
                <ion-chip v-for="mrt in post.nearest_mrt" :key="mrt.code"
                 @click.prevent="searchByMRT(mrt.code)"
                 color="secondary">
                  <ion-icon :icon="subwayOutline"></ion-icon>
                  <ion-label>{{ mrt.name }}</ion-label>
                </ion-chip>
              </ion-row>
              <ion-card-content>
                  {{ post.description }}
              </ion-card-content>
              <ion-row>
                <ion-col
                  size-sm="6" size-md="4" size-xs="12"
                  v-for="(feat, index) in post.features"  
                  :key="index"
                  class="feature ion-no-padding"
                >
                  <ion-item class="feat-item">
                    <ion-label>{{ feat.name }}</ion-label>
                    <ion-icon :icon="feat.value==='True' ? checkmarkCircle:closeCircle"></ion-icon>
                  </ion-item>
                </ion-col>
              </ion-row>              
            </ion-card>
            <ion-card v-if="state.hasMap">
              <ion-grid>
                <ion-row class="ion-justify-content-around">
                  <h5>Location Map</h5>
                </ion-row>
                <ion-row class="ion-justify-content-around">
                  <img :src="state.mapURL"/>
                </ion-row>
              </ion-grid>
            </ion-card>
            
            <!-- <ion-card>
              <ion-card-content>
                <ion-grid>
                  <ion-row class="ion-justify-content-around">
                    <ion-col
                      size-sm="8" size-md="10" size-xs="12"
                      v-for="(feat, index) in post.features"  
                      :key="index"
                    >
                    {{ feat.name }}
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card-content>
            </ion-card> -->
            </ion-col>
          </ion-row>
        </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, onMounted, reactive, 
         onBeforeMount, computed } from 'vue'
import { parseISO, formatDistance } from 'date-fns'
import Toolbar from '@/components/Toolbar.vue'
import { useRoute, useRouter } from 'vue-router'
import { getOneMapURL } from '@/utils'
import axios from '@/utils/api'
import { closeCircle, 
         checkmarkCircle,
         checkmarkCircleOutline, 
         subwayOutline,
         chatboxOutline } from 'ionicons/icons'

import { IonGrid, IonRow, IonCol,
        //  IonCardTitle,
         IonCardSubtitle, IonCardContent,
         IonPage, IonContent,
         IonSlides, IonSlide,
         IonImg, IonCard,
         IonLabel,
         IonItem,
         IonIcon,
         IonChip,
         IonButton, 
        //  IonButtons,
         // IonCheckbox,
        //  IonText
       } from '@ionic/vue'
import userStore from '@/store/user'
import chatStore from '@/store/chat'

export default defineComponent({
  name: 'AdDetail',
  props: {
    item: Object
  },
  components : {
    IonGrid, IonRow, IonCol,
    // IonCardTitle, 
    IonCardSubtitle,
    IonPage, IonContent,
    Toolbar, IonLabel,
    IonSlides, IonSlide,
    IonImg, IonCard, IonCardContent,
    IonItem, IonIcon,
    IonChip,
    IonButton,
    // IonButtons,
    // IonCheckbox,
    // IonText
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const state = reactive({
      posts: [],
      redirect: 'previous',
      mapURL: "",
      hasMap: computed(() => {
        return state.mapURL != ""
      })
    })
    
    const slideOpts = {
      initialSlide: 1,
      speed: 400
    };
    const api = `/sg/p/${route.params.category}/${route.params.id}/`

    function getRelativeDatetime(post) {
      if (post.created) {
        return formatDistance(parseISO(post.created), new Date())
      } else  {
        return ""
      }
    }

    onBeforeMount (() => {
      if ('redirect' in route.params) {
        state.redirect = route.params.redirect
      }
    })

    function setMapURL(post) {
      getOneMapURL(post)
        .then(mapx => {
          state.mapURL = mapx
        }
      )
    }

    function searchByMRT(mrt) {
      // console.log(mrt)
      router.push({
        name: 'listPosts',
        params: {
          countrycode: route.params.countrycode,
          category: route.params.category
        },
        query: {
          mrt: mrt
        }
        })      
    }

    onMounted (() => {
      if ('item' in route.params) {
         // console.log('item available!')
         const post = JSON.parse(route.params.item)
         // console.log(post)
         state.posts.push(post)
         setMapURL(post)
         document.title = `${state.posts[0].title} | Sukisan SG Pinoy Marketplace`
      } else {
        // console.log('fetching data from server')
        axios.get(api)
        .then(res => {
          const p = res.data
          console.log(p)
          state.posts.push(p)
          // state.mapURL = setMapURL(state.posts[0])
          setMapURL(state.posts[0])
          // console.log(state.mapURL)
          document.title = `${state.posts[0].title} | Sukisan SG Pinoy Marketplace`
        })
        .catch(e => {
          console.log(e.error_message)
        })
      }
      // console.log(`BASE_URL: ${process.env.VUE_APP_BASE_URL}`)

    })
    const showChat = (uid) => {
      if (!uid) {
        return false
      }
      return userStore.user.uid != uid
    }

    const initiateChat = async () => {
      if (!userStore.user.uid) {
        router.push({
          path: '/login',
          query: { redirect: route.fullPath }
          }
        )
      } else {
        try {
          const chatroom = await axios.post('/chat/initiate', 
          { "uid": state.posts[0].uid,
            "name": userStore.user.displayName
          })
          await chatStore.setRoomId(
            chatroom.data.roomId,
            chatroom.data.messages
            )
          console.log(`chatting...${chatStore.state.roomId}`)
          router.push({
            path: '/chat',
          })
        }
        catch (err){
          console.log(`error joining room: ${err.message}`)
        }
      }
    }

    return { state, slideOpts, getRelativeDatetime, 
      closeCircle, checkmarkCircle , 
      checkmarkCircleOutline, subwayOutline, 
      searchByMRT, chatboxOutline,
      userStore, showChat, 
      initiateChat
      }
  }
})
</script>

<style scoped>
ion-content {
  --background: #FAFFFE;
}
ion-card {
  box-shadow: none;
}

.green-bg {
  --background: var(--ion-color-primary-light);
  --border-radius: 30px;
  color: var(--ion-color-primary-text-dark);
  --box-shadow: none;
  --padding-start: 20px;
  --padding-end: 20px; 
}

.item_price {
  background: var(--ion-color-warning-tint);
  color: var(--ion-color-dark);
  border-radius: 30px;
  padding: 0px 20px 0px 20px;
  font-size: 16px;
  font-weight: 500;
  height: 2.2em;
  font-size: 16px;  
  margin: 4px 2px;
  display: flex;
  align-items: center;
}
.unit-type {
  background: var(--ion-color-light-shade);
  display: inline-block;
  padding: 7px 15px 7px 15px;
  border-radius: 30px;
  color: var(--ion-color-dark);
}
.post-images {
  margin-top: 10px;
  max-height: 50vh;
}
.post-images ion-img::part(image) {
  border-radius: 10px;
  max-height: 50vh;
}

.item-container {
  background: #F2F8F7;
  border-radius: 10px;
}

.flex-end {
  width:100%;
  display:flex;
  justify-content: flex-end !important;
}

.card-title {
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
}

ion-card-content {
  color: var(--ion-color-medium-shade);
  white-space: pre-line;
  padding:15px;
  margin: 10px 0px 10px 0px;
  border: solid 1px var(--ion-color-primary-text-dark);
  border-radius: 15px
}

ion-checkbox {
    --size: 24px;
    --checkmark-width: 2px;
    --border-width: 1px;
    --border-radius: var(--ion-border-radius);
    margin:5px;
}
.feature ion-label{
  margin: 0px;
}

.feature ion-icol {
  margin: 0px;
}

.feat-item ion-item {
    --inner-padding-top: 0px;
  --inner-padding-bottom: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
}

ion-checkbox::part(container) {
  padding:3px;
  margin:0px;
}

.fb-share-button {
  margin-top:3px;
}

</style>